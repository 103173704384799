export default {
  error_occurred: 'Tapahtui virhe. Yritä hetken kuluttua uudelleen.',
  new_content_available: "Sivulla on uusi päivitys, napsauta 'Päivitä' ladataksesi sivun uudelleen.",
  refresh_button_title: 'Virkistää',
  force_reload_message: 'Valitettavasti tapahtui virhe. Tiimimme työskentelee jo sen parissa. Yritä ladata verkkosivusto hetken kuluttua uudelleen.',
  sign_up: 'Luo tili',
  service: 'Palvelu',
  employee: 'Työntekijä',
  select_employee: 'Valitse henkilö',
  employees: 'Työntekijät',
  services: 'Palvelut',
  duration: 'Kesto',
  from: 'From',
  select_date: 'Valitse päivämäärä',
  no_account_question: 'Eikö sinulla ole vielä tiliä?',
  name: 'Etunimi',
  surname: 'Sukunimi',
  name_and_surname: 'Koko nimi',
  phone: 'Puhelin',
  password: 'Salasana',
  field_is_required: 'Tämä kenttä pitää täyttää.',
  value_is_too_long: 'Arvo on liian pitkä.',
  value_is_too_short: 'Arvo on liian lyhyt.',
  signup_was_successful: 'Ilmoittautuminen onnistui. Kirjautuminen paneeliin.',
  signup_has_failed: 'Rekisteröinti epäonnistui. Yritä hetken kuluttua uudelleen.',
  email_is_taken: 'Tili annetulla sähköpostiosoitteella on jo olemassa. Jos olet unohtanut salasanasi, käytä muistutusvaihtoehtoa.',
  email_or_password_incorrect: 'Sähköpostiosoite tai salasana on väärä. Jos olet unohtanut salasanasi, käytä muistutusvaihtoehtoa.',
  account_not_found: 'Tiliä ei löydy.',
  page_not_found: 'Oho! Etsimääsi sivua ei löytynyt :(',
  go_back_to_main_page: 'Palaa etusivulle ja yritä uudelleen.',
  bookings: 'Varaukset',
  account: 'Tilini',
  date: 'Päivämäärä',
  cancel: 'Peruuttaa',
  date_from: 'Päivämäärä alkaen',
  date_to: 'Päivämäärä',
  nothing_found_here: 'Mitään ei löytynyt.',
  old_password: 'Vanha salasana',
  remove: 'Poistaa',
  phone_is_invalid: 'Puhelinnumero on väärä.',
  book: 'Kirja',
  sign_up_2: 'Luo tili',
  log_in: 'Kirjaudu sisään',
  your_email_address: 'Sähköpostiosoitteesi',
  select: 'Valitse',
  filters: 'Suodattimet',
  expand: 'Laajentaa',
  collapse: 'Romahdus',
  bookings_drawer_title: 'Valitse suodattimet näyttääksesi vain etsimäsi varaukset.',
  clear_filters: 'Nollaa suodattimet',
  show_more: 'Näytä lisää',
  dont_you_remember_password: 'Unohditko salasanasi?',
  password_information: 'Salasanassa tulee olla vähintään 8 merkkiä, iso kirjain, numero ja erikoismerkki.',
  forgotten_password_button: 'Lähetä linkki salasanan vaihtamiseen',
  do_you_already_have_an_account: 'Onko sinulla jo tili?',
  reset_password_title: 'Aseta uusi salasana',
  reset_password_subtitle: 'Kirjoita uusi salasanasi alle.',
  reset_password_button: 'Tallenna uusi salasana',
  profile: 'Profiili',
  logout: 'Kirjaudu ulos',
  cancel_booking: 'Peruuta varauksesi',
  status_approved: 'Hyväksytty',
  status_payment: 'Odotetaan maksua',
  status_done: 'Valmis',
  status_cancelled: 'Peruutettu',
  cancel_booking_dialog_title: 'Haluatko peruuttaa varauksesi?',
  cancel_booking_dialog_information: 'Tätä toimintoa ei voi kumota!',
  cancel_booking_dialog_button: 'Kyllä, peruuta varaukseni',
  my_profile: 'Profiilini',
  my_data: 'Minun yksityiskohtani',
  upload_new_photo: 'Lisää uusi valokuva',
  birthday: 'Syntymäpäivä',
  save_changes: 'Tallenna muutokset',
  phone_number: 'Puhelinnumero',
  directional: 'Aluekoodi',
  save_new_password: 'Tallenna uusi salasana',
  new_password: 'Uusi salasana',
  password_change: 'Vaihda salasana',
  view: 'Näytä',
  services_2: 'Palvelut',
  accept_terms_and_conditions_1: 'Napsauttamalla alla olevaa vaihtoehtoa hyväksyn',
  accept_terms_and_conditions_2: 'Käyttöehdot',
  accept_terms_and_conditions_3: 'ja vahvistan, että olen lukenut',
  accept_terms_and_conditions_4: 'Tietosuojalausunto.',
  to: 'Vastaanottaja',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} euroa',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Kirjaudu sisään',
  email_is_incorrect: 'Sähköpostiosoite on väärä.',
  logged_out: 'Sinut on kirjattu ulos',
  booking_dialog_date_is_already_taken: 'Valittu aika ei ole käytettävissä. Haluaisitko valita toisen paikan?',
  data_search: 'Etsitään tietoja. Odota...',
  no_employees: 'Ei työntekijöitä',
  no_services: 'Ei palveluita',
  no_categories: 'Ei luokkia',
  no_bookings: 'Varauksia ei löytynyt.',
  no_subscriptions: 'Tilauksia ei löytynyt.',
  female: 'Nainen',
  male: 'Uros',
  other: 'Muut',
  no_numeric_in_password: 'Salasanassa ei ole numeerista merkkiä.',
  no_lowercase_in_password: 'Salasanassa ei ole pientä kirjainta.',
  no_uppercase_in_password: 'Ei isoja kirjaimia salasanassa.',
  no_special_character: 'Ei erikoishahmoa.',
  min_n_characters: 'Merkkien vähimmäismäärä on @{min}.',
  max_n_characters: 'Merkkien enimmäismäärä on @{max}.',
  email: 'Sähköposti',
  reset_password_fail_text: 'Salasanaa ei ole vaihdettu. Linkki on todennäköisesti vanhentunut.',
  reset_password_success_title: 'Salasanasi on vaihdettu.',
  reset_password_success_text: 'Voit kirjautua sisään uudella salasanallasi.',
  account_activation: 'Tilin aktivointi',
  account_activation_success: 'Tili on aktivoitu.',
  account_activation_fail: 'Tiliäsi aktivoinnissa ilmeni ongelma. Näyttää siltä, että tili on jo aktiivinen tai linkki ei ole enää voimassa.',
  account_activation_processing: 'Aktivoidaan tiliäsi. Odota...',
  account_activation_resend_title: 'Tiliä ei ole aktivoitu',
  account_activation_resend_text: 'Aktivoi tilisi napsauttamalla aktivointilinkkiä sähköpostissa, jonka lähetimme sinulle.',
  account_activation_resend_button: 'Lähetä uudestaan',
  confirm_send_activate_link_dialog_subtitle: 'Olemme lähettäneet aktivointilinkin sisältävän sähköpostin aiemmin antamaasi sähköpostiosoitteeseen.',
  file_is_too_large: 'Valittu tiedosto on liian suuri.',
  appointment_title: 'Valitse henkilö, päivämäärä ja varauksen aika',
  others: 'Muut',
  countryLabel: 'Maa',
  requiredMessages: 'Puhelinnumero vaaditaan',
  date_of_visit: 'Päivämäärä',
  hour_of_visit: 'Aika',
  thank_you_for_booking: 'Varauksesi',
  no_free_hours: 'Ei vapaita aikoja valittuna päivänä.',
  any_person: 'Satunnainen valinta',
  password_changed: 'salasanasi on vaihdettu',
  incorrect_old_password: 'Vanha salasana on väärä',
  error404: 'Virhe 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'OK',
  web_page_temporary_disabled: 'Anteeksi! Tämä sivu poistettiin väliaikaisesti käytöstä.',
  profile_update_success: 'Tietosi on tallennettu.',
  form_is_invalid: 'Lomake sisältää virheitä. Tarkista korostetut kentät ja yritä uudelleen.',
  search: 'Hae',
  pay_online_button_title: 'Maksa verkossa',
  account_not_activated_info: 'Sähköpostiosoitetta ei ole vahvistettu. Vahvista sähköpostiosoitteesi napsauttamalla lähetetyssä viestissä olevaa linkkiä.',
  paid: 'Maksettu',
  payments: 'Maksut',
  payment_service: 'Palvelu',
  payment_date: 'Päivämäärä',
  payment_price: 'Hinta',
  payment_success_title: 'Maksu onnistui, kiitos!',
  payment_fail_title: 'Maksu epäonnistui, yritä uudelleen.',
  pay_again: 'Maksa uudelleen',
  newsletter_subscribe_success: 'Kiitos! Sähköpostiosoitteesi on vahvistettu.',
  newsletter_subscribe_fail: 'Sähköpostiosoitteesi aktivoinnissa tapahtui ongelma. Näyttää siltä, että sähköpostiosoite on jo aktiivinen tai linkki ei ole enää voimassa.',
  newsletter_send_again: 'Lähetä uudestaan',
  newsletter_send_again_success: 'Aktivointilinkki on lähetetty uudelleen.',
  send_again_fail: 'Uuden aktivointilinkin luomisessa oli ongelma, ehkä sähköpostiosoitteesi on jo aktiivinen tai olet juuri luonut linkin.',
  newsletter_email_is_used: 'Sähköpostiosoite on jo listalla, jos osoitetta ei ole vahvistettu, voimme lähettää aktivointilinkin uudelleen.',
  newsletter_unsubscribe_success: 'Kiitos! Sähköpostiosoitteesi on poistettu.',
  newsletter_unsubscribe_fail: 'Sähköpostiosoitteen poistamisessa oli ongelma. Näyttää siltä, että sähköpostiosoite on jo poistettu tai linkki on vanhentunut.',
  booking_payments: 'Varaukset',
  simple_store_product_transactions: 'Tuotteet',
  payment_type_select_box: 'Valitse maksutapa',
  payment_description: 'Kuvaus',
  add_to_cart: 'Lisää ostoskoriin',
  remove_from_cart: 'Poista kärrystä',
  save: 'Tallentaa',
  bookings_cart_summary: 'Varauksen yhteenveto',
  today: 'Tänään',
  fill_booking_details_title: 'Anna tiedot',
  create_an_account_question: 'Luo tili ja lähetä salasana sähköpostiosoitteeseen',
  value_is_incorrect: 'Tämä arvo ei kelpaa.',
  first_available_date: 'Vapaita aikoja käytössä',
  service_location_1: 'Osoite',
  service_location_2: 'Puhelin',
  service_location_3: 'Google Meet',
  service_location_4: 'Muut',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Sijainti',
  booking_not_found: 'Varausta ei löytynyt.',
  booking_canceled: 'Varaus peruttiin.',
  booking_canceled_fail: 'Tätä varausta ei voi peruuttaa.',
  day: 'Päivä',
  week: 'Viikko',
  month: 'Kuukausi',
  year: 'Vuosi',
  booking_plural_1: 'Varaus',
  booking_plural_2: 'Varaukset',
  booking_plural_3: 'Varaukset',
  booking_plural_4: 'Varaukset',
  booking_plural_5: 'Varaukset',
  day_plural_1: 'Päivä',
  day_plural_2: 'Päivää',
  day_plural_3: 'Päivää',
  day_plural_4: 'Päivää',
  day_plural_5: 'Päivää',
  week_plural_1: 'Viikko',
  week_plural_2: 'Viikot',
  week_plural_3: 'Viikot',
  week_plural_4: 'Viikot',
  week_plural_5: 'Viikot',
  month_plural_1: 'Kuukausi',
  month_plural_2: 'Kuukaudet',
  month_plural_3: 'Kuukaudet',
  month_plural_4: 'Kuukaudet',
  month_plural_5: 'Kuukaudet',
  year_plural_1: 'Vuosi',
  year_plural_2: 'Vuosia',
  year_plural_3: 'Vuosia',
  year_plural_4: 'Vuosia',
  year_plural_5: 'Vuosia',
  tax: 'ALV TUNNUS',
  tax_prefix: 'Etuliite',
  get_company_data: 'Hae',
  get_company_data_failed: 'Tietoja ei voitu ladata.',
  company_name: 'Yrityksen nimi',
  street_and_number: 'Katu ja numero',
  postcode: 'Postinumero',
  city: 'Kaupunki',
  country: 'Maa',
  subscribe: 'Tilaa',
  recurrent_fee_info: 'Laskutetaan jokaisesta',
  one_time_fee_info: 'Kertamaksu.',
  new_account_title: 'Luo uusi tili',
  payment_data_title: 'Maksutiedot',
  billing_data_checkbox_question: 'Laskutustiedot',
  confirmation_modal_header: 'Toiminto vaatii vahvistuksen',
  confirmation_modal_warning: 'Oletko varma, että haluat jatkaa?',
  no_way_to_undo_action: 'Tätä toimintoa ei voi kumota!',
  delete_payment_method: 'Poista kortti',
  add_payment_method: 'Lisää kortti',
  fill_card_details: 'Täytä maksutiedot.',
  subscriptions: 'Tilaukset',
  subscription: 'Tilaus',
  invoice_number: 'Lasku',
  number: 'Määrä',
  status: 'Tila',
  details: 'Yksityiskohdat',
  created_at: 'Luotu',
  subscription_status_initiated: 'Palkaton',
  subscription_status_active: 'Aktiivinen',
  subscription_status_inactive: 'Epäaktiivinen',
  subscription_status_canceled: 'Peruutettu',
  ends_at: 'Päättyy klo',
  ends_at_default: 'Voimassa laskutuskauden loppuun asti',
  select_subscription_to_pay: 'Valitse maksava tilaus.',
  pay_with_subscription: 'Maksa tilauksella',
  bookings_paid_by_subscription_success_message: 'Maksettu',
  bookings_not_paid_by_subscription_error_message: 'Varausta ei voitu maksaa valitulla tilauksella.',
  stripe: 'Raita',
  provider_tag: 'Menetelmä',
  card: 'Kortti',
  cash: 'Käteinen raha',
  transfer: 'Siirtää',
  promo_code_label: 'Alennuskoodi',
  promo_code_invalid_message: 'Annettu alennuskoodi on virheellinen.',
  payment_method_setup_error: 'Kortissasi oli ongelma. Pankkisi on kieltäytynyt valtuuttamasta korttia myöhempiä tapahtumia varten. Ota yhteyttä pankkiisi.',
  active_subscription_missing: 'Tämän palvelun tilaaminen vaatii aktiivisen tilauksen. Valitettavasti sinulla ei ole tilausta käytettäväksi.',
  area_code: 'Aluekoodi',
  usage: 'Käyttö',
  create_account_in_stripe_failed: 'Maksuoperaattori on hylännyt tilaustietosi. Tarkista lomakkeen oikeellisuus (verotunnus, puhelinnumero, sähköpostiosoite).',
  additional_auth_required: 'Pankkisi vaatii lisävaltuutuksen',
  general_payment_error: 'Maksussasi oli ongelma. Korttisi ei ehkä tue tilauksia, sillä on ostorajoituksia tai sillä ei ole tarpeeksi varoja tilauksen maksamiseen. Ota yhteyttä pankkiisi tai valitse toinen maksutapa.',
  subscription_set_successfully: 'Tilaus asetettu oikein.',
  booking_created: 'Uusi varaus',
  booking_updated: 'Varauksen muutos',
  booking_deleted: 'Varauksen poistaminen',
  booking_reminder: 'Varausmuistutus',
  email_notifications: 'Sähköposti-ilmoitukset',
  sms_notifications: 'SMS-ilmoitukset',
  save_user_preferences: 'Tallenna asetukset',
  free_spots: 'Paikkoja jäljellä',
  no: 'Ei',
  yes: 'Joo',
  p24_inactive: 'Maksuoperaattoria Przelewy24 ei voi käyttää. Ota yhteyttä maksuoperaattoriisi.',
  stripe_customer_invalid: 'Virheellinen Stripe-asiakastunnus. Maksutapaa ei voi liittää asiakkaaseen.',
  stripe_resource_invalid: 'Valittua tuotetta ei ole saatavilla. Tarkista tuotteen saatavuus maksuoperaattorilta.',
  account_blocked_message: 'Tilisi ei ole aktiivinen. Ota yhteyttä ylläpitäjään.',
  time_zone: 'Aikavyöhyke',
  no_spots_left: 'Olet saavuttanut käytettävissä olevien paikkojen enimmäismäärän.',
  employee_auth_required: 'Kirjaudu tilillesi tai käytä toista sähköpostiosoitetta. Työntekijätilit vaativat kirjautumisen.',
  subpage_auth_required: 'Kirjaudu sisään tilillesi nähdäksesi välilehden.',
  gross: 'Bruttomäärä.',
  subscription_login_info: 'Onko sinulla jo tili? Ole hyvä ja kirjaudu sisään alla täyttääksesi tietosi.',
  change_date_time: 'Aikatauluta uudelleen',
  promo_code_checkbox: 'Minulla on alennuskoodi',
  booking_promo_code_label: 'Syötä alennuskoodi',
  select_hour: 'Valitse aika',
  id: 'Id',
  booking_rescheduled: 'Varauksesi on muuttunut.',
  booking_rescheduled_fail: 'Tätä varausta ei voi muuttaa.',
  max_user_bookings_error: 'Olet ylittänyt varausten enimmäismäärän. Valitse toinen palvelu tai ota yhteyttä.',
  in_total: 'Kaikki yhteensä',
  company: 'Yhtiö',
  individual: 'Yksityishenkilö',
  bulk_payment: 'Joukkomaksu',
  make_bulk_payment: 'Joukkomaksu',
  simple_store_product_confirmation_title: 'Täytä ostolomake',
  select_service_type: 'Valitse palveluvaihtoehto',
  add_to_calendar_button_label: 'Lisää kalenteriin',
  login_to_use_service_with_subscription: 'Kirjaudu sisään tilillesi varataksesi ajan tähän palveluun.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Valittu päivämäärä ei ole saatavilla. Valitse toinen vapaa päivämäärä. Varattaessa useita päivämääriä varmista, että päivämäärät eivät sulje toisiaan pois.',
  select_location_calendar_warning: 'Valitse sijainti, jotta voit ladata saatavilla olevat ajat.',
  selectable_location_online_title: 'Verkossa',
  change: 'Vaihda',
  show_less_hours_button: 'Näytä vähemmän tunteja',
  show_more_hours_button: 'Näytä enemmän tunteja',
  add_another_button_title: 'Lisää toinen',
  close: 'Sulje',
  selected_time_slots: 'Valitut aikavälit',
  select_location: 'Valitse sijainti',
  captcha_invalid_error_notification: 'Captcha-varmennus epäonnistui. Yritä ladata sivu uudelleen, jos ongelma jatkuu.',
  verify_code_incorrect_error: 'Syötetty koodi on virheellinen.',
  verify_code_too_many_requests: 'Emme voi luoda uutta koodia tällä hetkellä. Yritä myöhemmin uudelleen tai käytä koodia, jonka olemme lähettäneet.',
  send_again_button_title: 'Lähetä uudelleen',
  verify_title: 'Vahvista',
  verify_code_title: 'Syötä vahvistuskoodi',
  verify_code_description: 'Olemme lähettäneet sähköpostiisi 6-numeroinen vahvistuskoodi. Syötä se alla:',
  verification_invalid_error_notification: 'Virheellinen vahvistus. Yritä uudelleen myöhemmin.',
  too_many_requests: 'Ups! Toimit hieman liian nopeasti. Hidasta ja yritä uudelleen hetken kuluttua.'
}
